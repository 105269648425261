export const cleanUpObject = <T extends Object>(object: T) => {
  for (const key in object) {
    if (
      object[key] === false ||
      object[key] === undefined ||
      object[key] === null ||
      object[key] === '' ||
      (Array.isArray(object[key]) && object[key].length === 0) ||
      (typeof object[key] === 'object' && Object.keys(object[key]).length === 0)
    ) {
      delete object[key]
    }
  }
  return object
}

export const cleanUpObjectRecursive = <T extends Object>(object: T) => {
  for (const key in object) {
    if (
      object[key] === undefined ||
      object[key] === null ||
      object[key] === '' ||
      (Array.isArray(object[key]) && object[key].length === 0)
    ) {
      delete object[key]
    } else if (typeof object[key] === 'object') {
      cleanUpObjectRecursive(object[key])
      if (Object.keys(object[key]).length === 0) {
        delete object[key]
      }
    }
  }
  return object
}

export const objectToArrayByIncludedKeys = <T>(searchMap: Record<string, T>, keys: string[]) => {
  const array = []
  for (const key of keys) {
    array.push(searchMap[key])
  }

  return array
}

export const objectToArrayByExcludedKeys = <T>(searchMap: Record<string, T>, keys: string[]) => {
  const array = []
  for (const key in searchMap) {
    if (!keys.includes(key)) {
      array.push(searchMap[key])
    }
  }

  return array
}

/**
 * 객체 내에서 특정 필드명을 가진 값을 찾아 반환하는 함수
 * @param obj 검색할 객체
 * @param fieldName 찾고자 하는 필드명
 * @returns 찾은 필드의 값 또는 null
 */
export const searchDeepField = <T = any>(obj: Record<string, any>, fieldName: string): T | null => {
  if (!obj || typeof obj !== 'object') return null

  if (fieldName in obj) return obj[fieldName]

  return Object.values(obj).reduce((result, value) => result || searchDeepField(value, fieldName), null)
}
